@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600);
body {
	margin: 0;
	padding: 0;
	color: #373735;
	font-size: .9em;
	font-family: arial;
}

a {
	color: inherit;
	text-decoration: none;
}

a:visited {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: inherit;
	text-decoration: none;
}

.last {
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.navbar {
	width: 100%;
	padding: 0px;
}

.navbar-toggler {
	position: absolute;
	top: -75px;
	right: 30px;
  margin: 0px auto;
  margin-bottom: 20px;
  border: 1px solid #000;
}

.navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

li.nav-item a {
	line-height: 30px;
	padding: 5px 15px !important;
	text-align: center;
}

.nav-link.active, .nav-link:hover {
	position: relative;
	background: #ffcc33;
	font-weight: bold;
}

@media (min-width: 992px) {
	.navbar {
		float: right;
		width: auto;
		padding: 0px;;
		margin-top: 70px;
	}

	.nav-link.active:after {
		content: "";
		position: absolute;
		bottom: -9px;
		left: 10px;
		border-width: 10px 10px 0;
		border-style: solid;
		border-color: #ffcc33 transparent;
		display: block;
		width: 0;
		z-index: 1000;
	}
}

.react-tabs__tab-list {
	border: 0px;
}

.react-tabs__tab {
	background-color: #373735;
	color: #ffffff;
	border: 0px;
	border-radius: 0px;
}

.react-tabs__tab--selected {
	background-color: #ffcc33;
	color: #373735;
	border: 0px;
	border-radius: 0px;
	font-weight: bold;
}

.daytab .react-tabs__tab--selected:after {
	content: "";
	position: absolute;
	bottom: -9px;
	left: 30px;
	border-width: 10px 10px 0;
	border-style: solid;
	border-color: #ffcc33 transparent;
	display: block;
	width: 0;
}

